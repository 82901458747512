import Button from 'components/button/button';
import Modal from 'components/modal/modal';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { logoutTpx } from 'services/auth/logoutTpx';
import './style.scss';

interface Props {
    isOpen: boolean;
    closeModal: () => void;
}

export const Logout: FC<Props> = (props) => {
    const { i18n, t } = useTranslation();
    const navigate = useNavigate();

    const handleLogout = async () => {
        const response = await logoutTpx();
        if (response === 200) {
            window.location.href = 'https://touchpix.com';
        }
    };

    return (
        <Modal title={t('TITLE_LOGOUT')} isOpen={props.isOpen} closeModal={props.closeModal}>
            <div className='container-logout'>
                <p>{t('TEXT_LOGOUT')}</p>
                <div className='container-button'>
                    <Button onClick={props.closeModal} title={t('BUTTON_NOT_LOGOUT')} style='grey' />
                    <Button onClick={handleLogout} title={t('MENU_LOGOUT')} />
                </div>
            </div>
        </Modal>
    );
};

export default Logout;
