import { Navigate } from "react-router-dom";

interface Props {
    auth: undefined | boolean
    children: JSX.Element,
}

export const PrivateRoute = ({ auth, children }: Props) => {
    return auth ? children : <Navigate to="/" />
}
