import React, { FC, useRef } from 'react';
import { FileDrop } from 'react-file-drop';
import { useTranslation } from 'react-i18next';
import './style.scss';

interface Props {
    filesSelected: File[] | undefined;
    fileTypes: { name: string; format: string }[];
    setFiles: (files: File[]) => void;
    multiple?: boolean;
}

const FilesUpload: FC<Props> = (props) => {
    const { i18n, t } = useTranslation();
    const fileInputRef = useRef(null);

    const onFileInputChange = (e: any) => {
        const filesBySize = filterBySize(Array.from(e.target.files));
        props.setFiles(filesBySize);
    };

    const dropFiles = (files: File[]) => {
        const filesBySize = filterBySize(Array.from(files));

        const alloweds = props.fileTypes.map((type) => type.format);
        const allowedFiles = filesBySize.filter((file) => {
            if (alloweds.find((allow) => allow.toLowerCase().includes(file.type.toLowerCase()))) {
                return file;
            }
        });

        props.setFiles(allowedFiles);
    };

    const filterBySize = (files: File[]) => {
        return files.filter((file) => {
            return file.size / 1024 / 1024 <= 600; // 600MB
        });
    };

    const onTargetClick = () => {
        // @ts-ignore
        fileInputRef.current.click();
    };

    return (
        <div className='file-upload'>
            <input
                onChange={onFileInputChange}
                ref={fileInputRef}
                type='file'
                className='hidden'
                accept={props.fileTypes.map((type) => type.format).toString()}
                multiple={props.multiple}
            />
            <FileDrop onDrop={(files: any, event: any) => dropFiles(files)} onTargetClick={onTargetClick}>
                <img
                    src={`${process.env.PUBLIC_URL}/assets/img/file-select.svg`}
                    alt='Upload'
                    className='icon-upload'
                />
                {props.multiple ? <p>{t('UPLOAD_FILES_MULTIPLE')}</p> : <p>{t('UPLOAD_FILES_SINGLE')}</p>}
                {props.filesSelected === undefined || props.filesSelected.length === 0 ? (
                    <p className='files-selected'>{t('UPLOAD_FILES_NO_FILE')}</p>
                ) : (
                    <>
                        <p className='files-selected'>
                            {props.filesSelected.length === 1
                                ? `${t('UPLOAD_FILES_SELECTED_SINGLE')}: `
                                : `${t('UPLOAD_FILES_SELECTED_MULTIPLE')}: `}
                            {props.filesSelected.map((file) => (
                                <p>{file.name}</p>
                            ))}
                        </p>
                    </>
                )}
                <p className='files-allowed'>
                    {t('UPLOAD_FILES_MAX_SIZE')}:{' '}
                    {props.fileTypes.map((type, index) => {
                        return index === 0 ? <span>{type.name}</span> : <span>, {type.name}</span>;
                    })}
                </p>
            </FileDrop>
        </div>
    );
};
export default FilesUpload;
