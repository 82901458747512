import { LoaderConverter } from 'components/loaderConverter/loaderConverter';
import { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getToken } from 'services/auth/getToken';
import Cookie from 'utils/cookie';
import { ACCESS_TOKEN, REFRESH_TOKEN, TPX_CODE_VERIFIER } from 'constants/constants';
import { isValidToken } from 'services/auth/isValidToken';

const cookie = new Cookie();

interface Props {
    auth: undefined | boolean;
    setAuth: (state: boolean) => void;
}

export const Auth: FC<Props> = (props) => {
    const navigate = useNavigate();
    useEffect(() => {
        props.auth && navigate('/');
    }, [props.auth]);

    useEffect(() => {
        const validationToken = async () => {
            const urlParams = new URLSearchParams(window.location.search);
            const code = urlParams.get('code');
            const verifier = cookie.getCookie(TPX_CODE_VERIFIER);
            const token = cookie.getCookie(ACCESS_TOKEN);

            if (code !== null && verifier !== null) {
                const response = await getToken(code, verifier);
                cookie.deleteCookie(TPX_CODE_VERIFIER);
                if (response === 200) {
                    props.setAuth(true);
                    return;
                } else {
                    props.setAuth(false);
                    navigate('/login');
                }
            }

            if (token !== null) {
                await isValidToken()
                    .then((res) => {
                        if (res === 200) {
                            props.setAuth(true);
                        } else {
                            cookie.deleteCookie(ACCESS_TOKEN);
                            cookie.deleteCookie(REFRESH_TOKEN);
                            cookie.deleteAllCookies();
                            props.setAuth(false);
                            setTimeout(() => {
                                navigate('/login');
                            }, 1000);
                        }
                    })
                    .catch((err) => {
                        cookie.deleteCookie(ACCESS_TOKEN);
                        cookie.deleteCookie(REFRESH_TOKEN);
                        cookie.deleteAllCookies();
                        props.setAuth(false);
                        setTimeout(() => {
                            navigate('/login');
                        }, 1000);
                    });
            } else {
                props.setAuth(false);
                navigate('/login');
            }
        };

        validationToken();
    }, []);

    return (
        <div className='auth'>
            <LoaderConverter />
        </div>
    );
};

export default Auth;
