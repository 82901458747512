import axios from 'axios';

export const isValidToken = async () => {
    const host = 'https://touchpix.com/oauth/me';

    return axios
        .get(host)
        .then((res) => {
            return res.status;
        })
        .catch((err) => {
            console.log('err', err);
            return err;
        });
};
