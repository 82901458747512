import axios from 'axios';

export const uploadVideo = async (file: File, email: string) => {
    const url = 'https://converter.touchpix.com/conversions';

    const formData = new FormData();
    formData.append('video', file);
    formData.append('email', email);

    return axios({
        method: 'post',
        url: url,
        data: formData,
        headers: { 'Content-Type': 'multipart/form-data' },
    })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
};
