import Login from 'pages/login/login';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { PrivateRoute } from './privateRoute';
import Auth from 'pages/auth/auth';
import { useEffect, useState } from 'react';
import ConverterProresToHEVC from 'pages/converterProresToHEVC/converterProresToHEVC';

export const Router = () => {
    const navigate = useNavigate();
    const [auth, setAuth] = useState<undefined | boolean>(undefined);

    useEffect(() => {
        if (auth) {
            navigate('/converter');
        }
    }, [auth]);

    return (
        <Routes>
            <Route
                path='/converter'
                element={
                    <PrivateRoute auth={auth}>
                        <ConverterProresToHEVC />
                    </PrivateRoute>
                }
            />
            <Route path='/' element={<Auth auth={auth} setAuth={(state: boolean) => setAuth(state)} />} />
            <Route path='login' element={<Login auth={auth} />} />
        </Routes>
    );
};
