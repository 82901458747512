import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Router } from 'router/router';
import './index.scss';
require('./services/auth/interceptorToken');
require('./services/auth/interceptorRefresh');
require('./utils/i18n');

console.log(`${process.env.REACT_APP_NAME} version: ${process.env.REACT_APP_VERSION}`);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
    <React.StrictMode>
        <BrowserRouter basename={process.env.PUBLIC_URL}>
            <Router />
        </BrowserRouter>
    </React.StrictMode>
);
