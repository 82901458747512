import Button from 'components/button/button';
import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { loginTpx } from 'services/auth/loginTpx';
import './style.scss';

interface Props {
    auth: undefined | boolean;
}

export const Login: FC<Props> = (props) => {
    const { i18n, t } = useTranslation();
    const navigate = useNavigate();

    useEffect(() => {
        if (props.auth === undefined) {
            navigate('/');
        } else if (props.auth) {
            navigate('/editor');
        }
    }, [props.auth]);

    const handleLogin = () => {
        loginTpx();
    };

    return (
        <div className='login-page'>
            <div className='card-login'>
                <img src={`${process.env.PUBLIC_URL}/assets/img/touchpix-logo.png`} alt='Touchpix' />
                <p>{t('TEXT_LOGIN')}</p>
                <Button onClick={handleLogin} title={t('BUTTON_LOGIN')} />
            </div>
        </div>
    );
};

export default Login;
