import { TPX_CODE_VERIFIER } from 'constants/constants';
import Cookie from 'utils/cookie';

const cookie = new Cookie();

export const loginTpx = async () => {
    const baseUrl = 'https://touchpix.com/oauth/authorize/';
    // touchpix.com:
    const client = 'eE5dAsczelmBBc1a0AOi1yIGv4ZxDx4gmhkf4HaI';
    let redirectUriTest = 'http://localhost:3000/touchpix-converter';
    let redirectUri = process.env.REACT_APP_REDIRECT_URI || 'https://dev.tecdam.io/touchpix-converter/';
    const scope = 'basic';
    // Get Code Verifier.
    const verifier = generateVerifier();
    const challenge = await generateChallenge(verifier);

    if (
        window.location.hostname === 'localhost' ||
        window.location.hostname === '127.0.0.1' ||
        window.location.hostname === ''
    ) {
        // client = clientTest;
        redirectUri = redirectUriTest;
    }

    const url = new URL(baseUrl);
    url.searchParams.set('client_id', client);
    url.searchParams.set('response_type', 'code');
    url.searchParams.set('redirect_uri', redirectUri);
    url.searchParams.set('scope', scope);
    url.searchParams.set('code_challenge', challenge);
    url.searchParams.set('code_challenge_method', 'S256');

    cookie.setCookie(TPX_CODE_VERIFIER, verifier, 30);

    window.location.href = url.href;
};

const generateVerifier = () => {
    const array = new Uint32Array(28);
    window.crypto.getRandomValues(array);

    return Array.from(array, (item) => `0${item.toString(16)}`.substring(-2)).join('');
};

const generateChallenge = async (verifier: string) => {
    const sha256 = (plain: string) => {
        const encoder = new TextEncoder();
        const data = encoder.encode(plain);

        return window.crypto.subtle.digest('SHA-256', data);
    };

    const base64URLEncode = (string: ArrayBufferLike) => {
        return (
            window
                // @ts-ignore
                .btoa(String.fromCharCode.apply(null, new Uint8Array(string)))
                .replace(/\+/g, '-')
                .replace(/\//g, '_')
                .replace(/=/g, '')
        );
    };

    const hashed = await sha256(verifier);

    return base64URLEncode(hashed);
};
