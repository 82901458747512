import { ACCESS_TOKEN, REFRESH_TOKEN, TPX_CODE_VERIFIER } from 'constants/constants';
import Cookie from 'utils/cookie';

const cookie = new Cookie();

export const logoutTpx = async () => {
    const host = 'https://touchpix.com/oauth/destroy';
    const token = cookie.getCookie(ACCESS_TOKEN);

    if (token === null) return;
    const params = new FormData();
    params.append('access_token', token);

    // Make a POST request
    try {
        const response = await fetch(host, {
            method: 'POST',
            body: params,
        });

        const { status } = response;
        if (status === 200) {
            cookie.deleteCookie(ACCESS_TOKEN);
            cookie.deleteCookie(REFRESH_TOKEN);
            cookie.deleteCookie(TPX_CODE_VERIFIER);
            cookie.deleteAllCookies();
        }
        return status;
    } catch (error) {
        console.log(error);
        return 400;
    }
};
