export default class Validators {
    static instance: Validators = new Validators();

    validEmail(email: string): boolean {
        if (email !== '') {
            let reg = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            const emailValid = reg.test(email) !== true;

            return emailValid ? true : false;
        } else {
            return false;
        }
    }
}
