import { ACCESS_TOKEN, REFRESH_TOKEN, REFRESH_TOKEN_EXPIRY_TIME, TOKEN_EXPIRY_TIME } from 'constants/constants';
import Cookie from 'utils/cookie';

const cookie = new Cookie();

export const getToken = async (code: string, verifier: string) => {
    const host = 'https://touchpix.com/oauth/token';
    // touchpix.com:
    const client = 'eE5dAsczelmBBc1a0AOi1yIGv4ZxDx4gmhkf4HaI';
    let redirectUriTest = 'http://localhost:3000/touchpix-converter';
    let redirectUri = process.env.REACT_APP_REDIRECT_URI || 'https://dev.tecdam.io/touchpix-converter/';
    const grantType = 'authorization_code';

    // Get code from query params
    // const urlParams = new URLSearchParams(window.location.search);
    // const code = urlParams.get('code');

    if (
        window.location.hostname === 'localhost' ||
        window.location.hostname === '127.0.0.1' ||
        window.location.hostname === ''
    ) {
        // client = clientTest;
        redirectUri = redirectUriTest;
    }

    if (code === null) return;
    // Build params to send to token endpoint
    const params = new FormData();
    params.append('client_id', client);
    params.append('grant_type', grantType);
    params.append('code_verifier', verifier);
    params.append('redirect_uri', redirectUri);
    params.append('code', code);

    // Make a POST request
    try {
        const response = await fetch(host, {
            method: 'POST',
            body: params,
        });

        const { status } = response;
        if (status === 200) {
            const data = await response.json();
            cookie.setCookie(ACCESS_TOKEN, data.access_token, TOKEN_EXPIRY_TIME);
            cookie.setCookie(REFRESH_TOKEN, data.refresh_token, REFRESH_TOKEN_EXPIRY_TIME);
            return status;
        }
        {
            return status;
        }
    } catch (error) {
        console.log(error);
        return 400;
    }
};
