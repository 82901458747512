import Button from 'components/button/button';
import Logout from 'components/logout/logout';
import { FC, useState } from 'react';
import './style.scss';
import { useTranslation } from 'react-i18next';

interface Props {}

export const Header: FC<Props> = (props) => {
    const { i18n, t } = useTranslation();
    const [isOpenLogout, setIsOpenLogout] = useState(false);

    return (
        <header className='header'>
            {isOpenLogout && <Logout isOpen={isOpenLogout} closeModal={() => setIsOpenLogout(false)} />}
            <div className='container inner-header'>
                <div className='container-logo'>
                    <img src={`${process.env.PUBLIC_URL}/assets/img/touchpix-logo.png`} alt='Touchpix' />
                </div>

                <Button style='grey' onClick={() => setIsOpenLogout(true)} title={t('MENU_LOGOUT')} />
            </div>
        </header>
    );
};

export default Header;
