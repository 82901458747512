import FilesUpload from 'components/filesUpload/filesUpload';
import Header from 'components/header/header';
import InputField from 'components/inputField/inputField';
import Button from 'components/button/button';
import useDebounce from 'hooks/useDebounce';
import { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Validators from 'utils/validators';
import './style.scss';
import { uploadVideo } from 'services/uploadVideo/uploadVideo';
import Loader from 'components/loader/loader';

const validators = Validators.instance;

export const ConverterProresToHEVC = () => {
    const { i18n, t } = useTranslation();
    const [file, setFile] = useState<File | undefined>(undefined);
    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState(false);
    const [disabledButton, setDisabledButton] = useState(true);

    const [loaderForm, setLoaderForm] = useState(false);
    const [successForm, setSuccessForm] = useState(false);
    const [failForm, setFailForm] = useState(false);

    const debouncedEmail = useDebounce(email, 400);
    useEffect(() => {
        if (email !== '') {
            const valField = validators.validEmail(email);
            setEmailError(valField);
        } else {
            setEmailError(false);
        }
    }, [debouncedEmail]);

    useEffect(() => {
        setDisabledButton(!validateSubmit());
    }, [file, email, emailError]);

    const validateSubmit = () => {
        if (file !== undefined && email !== '' && !emailError) {
            return true;
        } else {
            return false;
        }
    };

    const handleSubmit = (e: any) => {
        e.preventDefault();
        if (validateSubmit() && file !== undefined) {
            setLoaderForm(true);
            setSuccessForm(false);
            setFailForm(false);

            uploadVideo(file, email)
                .then((res) => {
                    if (res.status === 200) {
                        setSuccessForm(true);
                    } else {
                        setFailForm(true);
                    }
                })
                .catch(() => {
                    setFailForm(true);
                })
                .finally(() => {
                    setFile(undefined);
                    setEmail('');
                    setLoaderForm(false);
                });
        }
    };

    return (
        <>
            <Header />
            <section className="container converter-prorest-hevc">
                <h1>{t('TITLE_CONVERTER')}</h1>
                {loaderForm ? (
                    <div className="container-loader">
                        <Loader />
                        <h3>{t('WAIT_TEXT')}</h3>
                    </div>
                ) : (
                    <>
                        <form className="container-form" onSubmit={handleSubmit}>
                            <p>
                                {t('EXPLANATION_TEXT')}
                                <a href="https://touchpix.com/knowledge-base/convert-a-proress-4444-video-with-alpha-to-hevc-with-alpha-on-a-macbook/">
                                    {t('EXPLANATION_MORE_INFO')}
                                </a>
                            </p>
                            <p>{t('EXPLANATION_TEXT_2')}</p>
                            <FilesUpload
                                fileTypes={[{ name: '.mov', format: 'video/quicktime' }]}
                                setFiles={(files) => setFile(files[0])}
                                filesSelected={file === undefined ? undefined : [file]}
                            />
                            <div className="container-bottom">
                                <InputField
                                    label={t('EMAIL_FORM')}
                                    value={email}
                                    onChange={(e: ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
                                    type={'email'}
                                    error={emailError}
                                    errorText={t('EMAIL_ERROR_FORM')}
                                />
                                <Button
                                    onClick={(e: any) => handleSubmit(e)}
                                    title={t('SUBMIT_FORM')}
                                    disabled={disabledButton}
                                />
                            </div>
                        </form>
                        {successForm && <p>{t('SUCCESS_TEXT')}</p>}
                        {failForm && <p>{t('FAIL_TEXT')}</p>}
                    </>
                )}
            </section>
        </>
    );
};

export default ConverterProresToHEVC;
