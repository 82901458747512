import { FC } from 'react';
import './style.scss';

interface Props {
    onClick: (e: any) => void;
    title: string;
    style?: 'grey' | 'yellow' | 'red';
    disabled?: boolean;
}

export const Button: FC<Props> = (props) => {
    return (
        <button
            className={`custom-button ${props.style && props.style} ${props.disabled && 'disabled'}`}
            onClick={props.onClick}
            disabled={props.disabled}
        >
            {props.title}
        </button>
    );
};

export default Button;
