import './style.scss';

export const Loader = () => {
    return (
        <img 
            src={`${process.env.PUBLIC_URL}/assets/img/loader.png`} 
            alt='loader'
            className='spinner'
        />
    );
}

export default Loader;
