import React, { ChangeEvent, FC } from 'react';
import './style.scss';

interface Props {
    label: string;
    value: string;
    onChange: (e: ChangeEvent<HTMLInputElement>) => void;
    type: string;
    error: boolean;
    errorText: string;
}

const InputField: FC<Props> = (props) => {
    return (
        <div className='input-field'>
            <span className='label'>{props.label}</span>
            <input
                type={props.type}
                value={props.value}
                onChange={(e: ChangeEvent<HTMLInputElement>) => props.onChange(e)}
                className={`${props.error && 'error'}`}
            />
            {props.error && <span className='error-text'>{props.errorText}</span>}
        </div>
    );
};
export default InputField;
