import Loader from 'components/loader/loader';
import './style.scss';

export const LoaderConverter = () => {
    return (
        <div className='loader-converter'>
            <Loader />
        </div>
    );
};

export default LoaderConverter;
