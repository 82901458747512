import React, { FC } from 'react';
import { ReactI18NextChild } from 'react-i18next';
import ModalReact from 'react-modal';
import './style.scss';

interface Props {
    children: ReactI18NextChild | Iterable<ReactI18NextChild>;
    title: string;
    isOpen: boolean;
    closeModal: any;
    hiddeHeader?: boolean;
}

const Modal: FC<Props> = (props) => {
    return (
        <ModalReact
            isOpen={props.isOpen}
            ariaHideApp={false}
            shouldCloseOnOverlayClick
            onRequestClose={props.closeModal}
        >
            {!props.hiddeHeader && (
                <div className='header-modal'>
                    <h3>{props.title}</h3>
                    <button onClick={props.closeModal}>
                        <div className='close'></div>
                    </button>
                </div>
            )}
            <div className='content-modal'>{props.children}</div>
        </ModalReact>
    );
};
export default Modal;
